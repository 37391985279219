import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';

class Redirect extends Component {
	render() {
		const {
			pageContext: { to },
		} = this.props;

		return (
			<Fragment>
				<div className="w-container w-container--padded">
					<h2
						className="articleheader center"
						dangerouslySetInnerHTML={{ __html: 'Redirecting...' }}
					/>

					<div className="w-row">
						<div className="w-col w-col-12">
							<div className="user-content">
								<p>
									If you are not automatically redirected. Please{' '}
									<Link to={to}>click here</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Redirect;
